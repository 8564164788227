import React from 'react';

function ProgressBar() {
  return (
    <div className="KW_progressContainer">
        <div className="KW_progressBar">
        </div>
    </div>
    );
}

export default ProgressBar;
